import {UserSummary} from '../../state/recoil';

export const USER_SUMMARIES = fetch('./users-summary-data.json'
    , {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    },
)
    .then(function(response) {
      return response.json();
    })
    .then(function(myJson) {
      const v = myJson as any as Record<string, UserSummary>;
      return v;
    });
