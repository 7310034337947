/* eslint-disable max-len */
type UserRefs = [string, string[]][];

export const USER_REFS: UserRefs = [
  ['lusinekhachatur@outlook.com', []],
  ['narandelger.nar@gmail.com', []],
  ['nina_prazyan@aybschool.am', []],
  ['kavkasyan@gmail.com', []],
  ['iren.harutyunyan.y@tumo.org', []],
  ['haykstepanyan02@gmail.com', []],
  ['grigory@uni.minerva.edu', ['narandelger.nar@gmail.com', 'kavkasyan@gmail.com', 'iren.harutyunyan.y@tumo.org', 'haykstepanyan02@gmail.com', 'kristinetonoyan1@gmail.com', 'alexchobanyan123@gmail.com', 'lilit.petrosyan@berkeley.edu', 'maggie@uni.minerva.edu', 'nourien@uni.minerva.edu', 'artazyan.ilona@gmail.com', 'vladislav@uni.minerva.edu', 'gegham_zakaryan@edu.aua.am', 'haidergillani@uni.minerva.edu', 'candace@uni.minerva.edu', 'nicolewang25@uni.minerva.edu', 'anipet@seas.upenn.edu', 'tehseenbukhari333@gmail.com']],
  ['kristinetonoyan1@gmail.com', []],
  ['paolodiaz102@gmail.com', ['ashookenhuff@gmail.com', 'sebassilupu6@gmail.com', 'mariafernanda.vacca@gmail.com', 'dairavefons@hotmail.com', 'malesdavid54@gmail.com', 'itssree2006@gmail.com', 'ananyam@muwci.net', 'malesdavid54@gmail.com', 'hma226@lwhigh.edu', 'flavia.morenob@gmail.com', 'campbellb.arianna@gmail.com', 'mllanquechaleon@gmail.com', 'genesisloreparedes@gmail.com', 'lsalvador.ht@gmail.com', '15catalu.5c@gmail.com', 'alessiaaparcana@gmail.com', 'aritroimportant123@gmail.com', 'spalraja@macalester.edu', 'teinakamura@hotmail.com', 'is105@wellesley.edu', 'chikkiyer@gmail.com', 'tanishasinghh28@gmail.com', 'nimrat1108@gmail.com', 'jorge.arriola2183@gmail.com', 'vhazra@u.rochester.edu', 'durahalbadr@gmail.com', 'esslamhosam@gmail.com', 'angeloscharalampos.bouras@richmond.edu', 'gu.vazquez2004@gmail.com', '2024.anisa.adiba.faryat@uwcisak.jp']],
  ['martirosyan.agnesa@gmail.com', []],
  ['alexchobanyan123@gmail.com', ['chodenis987@gmail.com']],
  ['chodenis987@gmail.com', []],
  ['anuliksargsyan02@gmail.com', []],
  ['lilit.petrosyan@berkeley.edu', []],
  ['tehseenbukhari333@gmail.com', ['tehseenbukhari333@gmail.com']],
  ['maggie@uni.minerva.edu', []],
  ['nourien@uni.minerva.edu', []],
  ['janbartkowiak@uni.minerva.edu', []],
  ['miray@uni.minerva.edu', []],
  ['inga_tumanyan@aybschool.am', []],
  ['artazyan.ilona@gmail.com', ['inga_tumanyan@aybschool.am', 'emil_panosyan@aybschool.am', 'anush_mashinyan@aybschool.am']],
  ['emil_panosyan@aybschool.am', ['Narek_davtyan@aybschool.am', 'hrachboryan@mail.ru', 'davidustabashyan45@gmail.com', 'sargsyanvahan68@gmail.com', 'sardaryan.samvel@bk.ru']],
  ['Narek_davtyan@aybschool.am', []],
  ['hrachboryan@mail.ru', []],
  ['davidustabashyan45@gmail.com', []],
  ['sargsyanvahan68@gmail.com', []],
  ['tamasflavia25@gmail.com', []],
  ['sardaryan.samvel@bk.ru', []],
  ['eduard.zippenfenig@gmail.com', []],
  ['vladislav@uni.minerva.edu', []],
  ['anush_mashinyan@aybschool.am', ['viktoria.navasardyan@bk.ru', 'arpi.tokhmakhyan@gmail.com']],
  ['armenuhi.stepanyan301@gmail.com', []],
  ['viktoria.navasardyan@bk.ru', []],
  ['nare.kurghinyan.y@tumo.org', []],
  ['vero123498@outlook.com', []],
  ['arpi.tokhmakhyan@gmail.com', []],
  ['gegham_zakaryan@edu.aua.am', []],
  ['iraminamdar943@gmail.com', []],
  ['haidergillani@uni.minerva.edu', []],
  ['mihnea.grigore@saguna.ro', []],
  ['candace@uni.minerva.edu', []],
  ['prazyannina@gmail.com', []],
  ['nicolewang25@uni.minerva.edu', []],
  ['nicole020101@gmail.com', []],
  ['Meri_Tigranyan@aybschool.am', []],
  ['anipet@seas.upenn.edu', []],
  ['ammory-25@hotmail.com', []],
  ['Hibonizbm@gmail.com', ['broski0916@outlook.com']],
  ['ashookenhuff@gmail.com', []],
  ['sebassilupu6@gmail.com', []],
  ['mariafernanda.vacca@gmail.com', []],
  ['dairavefons@hotmail.com', []],
  ['malesdavid54@gmail.com', []],
  ['itssree2006@gmail.com', []],
  ['ananyam@muwci.net', []],
  ['hma226@lwhigh.edu', []],
  ['flavia.morenob@gmail.com', []],
  ['campbellb.arianna@gmail.com', []],
  ['mllanquechaleon@gmail.com', ['vascov@stanford.edu', 'carlos.atalluz@gmail.com']],
  ['vascov@stanford.edu', ['yampier.chilcon.saboya@gmail.com', 'leiaanapaula@berkeley.edu']],
  ['genesisloreparedes@gmail.com', []],
  ['lsalvador.ht@gmail.com', []],
  ['15catalu.5c@gmail.com', []],
  ['carlos.atalluz@gmail.com', []],
  ['carlos.rgaimer@gmail.com', ['74374933@lima.coar.edu.pe']],
  ['74374933@lima.coar.edu.pe', []],
  ['alessiaaparcana@gmail.com', []],
  ['aritroimportant123@gmail.com', []],
  ['spalraja@macalester.edu', []],
  ['dgebyc@gmail.com', []],
  ['teinakamura@hotmail.com', []],
  ['is105@wellesley.edu', []],
  ['chikkiyer@gmail.com', ['kassahunredeat@gmail.com', 'lunakanagawa@gmail.com', 'astrami.ru@gmail.com', 'juliette.robieux@sciencespo.fr', 'navya.srivastava@sciencespo.fr', 'nicolecalista@hotmail.com']],
  ['tanishasinghh28@gmail.com', []],
  ['nimrat1108@gmail.com', []],
  ['jorge.arriola2183@gmail.com', []],
  ['vhazra@u.rochester.edu', ['tanishwasp10@gmail.com']],
  ['kassahunredeat@gmail.com', []],
  ['lunakanagawa@gmail.com', []],
  ['astrami.ru@gmail.com', []],
  ['juliette.robieux@sciencespo.fr', []],
  ['navya.srivastava@sciencespo.fr', []],
  ['kv2375@columbia.edu', []],
  ['yampier.chilcon.saboya@gmail.com', []],
  ['nicolecalista@hotmail.com', []],
  ['durahalbadr@gmail.com', []],
  ['esslamhosam@gmail.com', []],
  ['leiaanapaula@berkeley.edu', []],
  ['angeloscharalampos.bouras@richmond.edu', ['aggeobrs@gmail.com', 'pkatogian@yahoo.com', 'martirosyan.agnesa@gmail.com']],
  ['gu.vazquez2004@gmail.com', []],
  ['2024.anisa.adiba.faryat@uwcisak.jp', []],
  ['rchavarriadavila@gmail.com', []],
  ['sevak.d13@gmail.com', []],
  ['broski0916@outlook.com', []],
  ['tanishwasp10@gmail.com', []],
  ['aggeobrs@gmail.com', []],
  ['pkatogian@yahoo.com', []]];
